<template>
	<CRow>
		<CCol>
			<h4 class="my-4">
				Pre-order limit
			</h4>
			<CRow class="form-group mb-4">
				<CCol md="6" class="mt-2">
					<label>Limit per user</label>
					<BaseInputNumber
						v-model="localLimitPerUser"
						text-align="left"
						placeholder="Unlimited Item"
						@input="handleLimitPerUserChange"
					/>
				</CCol>
				<CCol md="6" class="mt-2">
					<label>Limit per order</label>
					<BaseInputNumber
						v-model="localLimitPerOrder"
						text-align="left"
						placeholder="Unlimited Item"
						@input="handleLimitPerOrderChange"
					/>
				</CCol>
			</CRow>

			<!-- Override payment period -->
			<CRow class="form-group mt-2 mb-4">
				<CCol>
					<div class="label mt-3">
						Override payment order expire period
					</div>
				</CCol>
				<CCol md="3">
					<div class="form-group-switch mt-3">
						<CSwitch
							:checked="localIsOverridePaymentPeriod"
							variant="3d"
							size="sm"
							class="switch-custom"
							color="success"
							@update:checked="handleIsOverridePeriodChange"
						/>
						<span class="typo-body-2">
							{{ isOverridePaymentPeriod ? 'On' : 'Off' }}
						</span>
					</div>
				</CCol>
				<CCol md="12" class="mt-3">
					<div v-if="isOverridePaymentPeriod" class="bg-gray-100 color-black-45 p-4">
						<CRow>
							<CCol md="6">
								<BaseInputNumber
									v-model="localOverridePaymentPeriodHourValue"
									text-align="left"
									placeholder="Expire in"
									label="Expires in hour"
									append-text="hr."
									@input="handleOverridePaymentPeriodHourValueChange"
								/>
							</CCol>
							<CCol md="6">
								<BaseInputNumber
									v-model="localOverridePaymentPeriodMinuteValue"
									text-align="left"
									placeholder="Expire in"
									label="Expires in minute"
									append-text="min."
									@input="handleOverridePaymentPeriodMinuteValueChange"
								/>
							</CCol>
						</CRow>
					</div>
				</CCol>
			</CRow>
			<!-- Override payment period -->

			<hr class="mt-2">
		</CCol>
	</CRow>
</template>

<script>
export default {
	name: 'PreOrderLimit',
	props: {
		limitPerUser: {
			type: [Number, String],
			default: null,
		},
		limitPerOrder: {
			type: [Number, String],
			default: null,
		},
		isOverridePaymentPeriod: {
			type: Boolean,
			default: false,
		},
		overridePaymentPeriodHourValue: {
			type: [Number, String],
			default: null,
		},
		overridePaymentPeriodMinuteValue: {
			type: [Number, String],
			default: null,
		},
	},
	data() {
		return {
			localLimitPerUser: this.limitPerUser,
			localLimitPerOrder: this.limitPerOrder,
			localIsOverridePaymentPeriod: this.isOverridePaymentPeriod,
			localOverridePaymentPeriodHourValue: this.overridePaymentPeriodHourValue,
			localOverridePaymentPeriodMinuteValue: this.overridePaymentPeriodMinuteValue,
		};
	},
	methods: {
		handleLimitPerUserChange(value) {
			this.$emit('update:limitPerUser', value);
		},
		handleLimitPerOrderChange(value) {
			this.$emit('update:limitPerOrder', value);
		},
		handleIsOverridePeriodChange(value) {
			this.$emit('update:isOverridePaymentPeriod', value);
		},
		handleOverridePaymentPeriodHourValueChange(value) {
			this.$emit('update:overridePaymentPeriodHourValue', value);
		},
		handleOverridePaymentPeriodMinuteValueChange(value) {
			this.$emit('update:overridePaymentPeriodMinuteValue', value);
		},
	},
};
</script>
