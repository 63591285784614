<template>
	<div class="main-wrapper-large pt-4">
		<CRow>
			<PreOrderSidebar @onClickNav="scrollToSection" />
			<CCol sm="8">
				<form
					class="form-preorder"
					@submit.prevent="handleSubmit"
				>
					<div class="mb-5">
						<slot name="header"></slot>
						<div>
							<PreOrderGeneralInfo
								:ref="PREORDER_REFS.GENERAL_INFO"
								:is-edit="isEdit"
								:pre-order-name.sync="generalInfo.preOrderName"
								:pre-order-status.sync="generalInfo.preOrderStatus"
								:pre-order-payment-option.sync="generalInfo.preOrderPaymentOption"
							/>
							<PreOrderSku
								:ref="PREORDER_REFS.PRE_ORDER_SKU"
								:pre-order-payment-option.sync="generalInfo.preOrderPaymentOption"
								:pre-order-skus.sync="campaignSku.preOrderSkus"
								:pre-order-is-allowed-restrict-product.sync="campaignSku.preOrderIsAllowedRestrictProduct"
								:pre-order-is-restrict-all-products.sync="campaignSku.preOrderIsRestrictAllProducts"
								:pre-order-restrict-skus.sync="campaignSku.preOrderRestrictSkus"
							/>
							<PreOrderProductPriceDisplaying
								:ref="PREORDER_REFS.PRODUCT_PRICE_DISPLAY"
								:is-hide-product-price-display.sync="productPriceDisplay.isHideProductPriceDisplay"
								:price-unavailable-description-th.sync="productPriceDisplay.priceUnavailableDescriptionTh"
								:price-unavailable-description-en.sync="productPriceDisplay.priceUnavailableDescriptionEn"
							/>
							<PreOrderSchedule
								:ref="PREORDER_REFS.SCHEDULE"
								:is-edit="isEdit"
								:pre-order-payment-option="generalInfo.preOrderPaymentOption"
								:pre-order-start-date.sync="scheduleInfo.preOrderStartDate"
								:pre-order-start-time.sync="scheduleInfo.preOrderStartTime"
								:pre-order-end-date.sync="scheduleInfo.preOrderEndDate"
								:pre-order-end-time.sync="scheduleInfo.preOrderEndTime"
								:is-time-counter-visible.sync="scheduleInfo.isTimeCounterVisible"
								:payment-start-date.sync="scheduleInfo.paymentStartDate"
								:payment-start-time.sync="scheduleInfo.paymentStartTime"
								:payment-end-date.sync="scheduleInfo.paymentEndDate"
								:payment-end-time.sync="scheduleInfo.paymentEndTime"
								:period-status="scheduleInfo.periodStatus"
								:remaining-status="scheduleInfo.remainingStatus"
								:released-status="scheduleInfo.releasedStatus"
								:release-date.sync="scheduleInfo.releaseDate"
								:release-time.sync="scheduleInfo.releaseTime"
								:release-title-en.sync="scheduleInfo.releaseTitleEn"
								:release-title-th.sync="scheduleInfo.releaseTitleTh"
								:release-description-en.sync="scheduleInfo.releaseDescriptionEn"
								:release-description-th.sync="scheduleInfo.releaseDescriptionTh"
							/>
							<PreOrderLimit
								:ref="PREORDER_REFS.PRE_ORDER_LIMIT"
								:limit-per-user.sync="limit.limitPerUser"
								:limit-per-order.sync="limit.limitPerOrder"
								:is-override-payment-period.sync="limit.isOverridePaymentPeriod"
								:override-payment-period-hour-value.sync="limit.overridePaymentPeriodHourValue"
								:override-payment-period-minute-value.sync="limit.overridePaymentPeriodMinuteValue"
							/>
							<PreOrderDeliveryShipping
								:ref="PREORDER_REFS.DELIVERY_SHIPPING"
								:pre-order-id="preOrderId"
								:pre-order-payment-option="generalInfo.preOrderPaymentOption"
								:is-available.sync="deliveryShippingInfo.isAvailable"
								:is-standard-delivery-available.sync="deliveryShippingInfo.isStandardDeliveryAvailable"
								:standard-delivery-en.sync="deliveryShippingInfo.standardDeliveryEn"
								:standard-delivery-th.sync="deliveryShippingInfo.standardDeliveryTh"
								:is-express-delivery-available.sync="deliveryShippingInfo.isExpressDeliveryAvailable"
								:express-delivery-en.sync="deliveryShippingInfo.expressDeliveryEn"
								:express-delivery-th.sync="deliveryShippingInfo.expressDeliveryTh"
								:payment-method-settings.sync="deliveryShippingInfo.paymentMethodSettings"
								@onLoadedPaymentMethod="isLoadingPaymentMethod = false"
							/>
							<PreOrderPickupAtStore
								:ref="PREORDER_REFS.PICKUP_AT_STORE"
								:is-edit="isEdit"
								:pre-order-id="preOrderId"
								:rules="pickupAtStore.rules"
								:is-editable="pickupAtStore.isRulesEditable"
								:pre-order-payment-option="generalInfo.preOrderPaymentOption"
								:payment-method-settings.sync="pickupAtStore.paymentMethodSettings"
								:is-loading-payment-method="isLoadingPaymentMethod"
								@onCreateConditionClick="$refs['modal-pickup-at-store-confirm'].open()"
							/>
							<PreOrderGrabAndGo
								:ref="PREORDER_REFS.GRAB_AND_GO"
								:pre-order-id="preOrderId"
								:is-available.sync="grabAndGo.isAvailable"
								:branch-ids.sync="grabAndGo.branchIds"
								:is-loading-payment-method="isLoadingPaymentMethod"
								:pre-order-payment-option="generalInfo.preOrderPaymentOption"
								:payment-method-settings.sync="grabAndGo.paymentMethodSettings"
							/>
							<PreOrderLabel
								:ref="PREORDER_REFS.PRE_ORDER_LABEL"
								:primary-labels.sync="label.primary"
							/>
							<PreOrderCampaignInformation
								:ref="PREORDER_REFS.CAMPAIGN_INFORMATION"
								:campaign-information-th.sync="campaignInfo.campaignInformationTh"
								:campaign-information-en.sync="campaignInfo.campaignInformationEn"
							/>
							<PreOrderTermAndCondition
								:ref="PREORDER_REFS.TERM_AND_CONDITION"
								:is-store-id-card.sync="termsAndConditions.isStoreIdCard"
								:terms-and-conditions-th.sync="termsAndConditions.termsAndConditionsTh"
								:terms-and-conditions-en.sync="termsAndConditions.termsAndConditionsEn"
							/>
							<PreOrderCustomButton
								:ref="PREORDER_REFS.CUSTOM_BUTTON"
								:form-data="customButton"
							/>
						</div>
					</div>
					<BaseActionPanelStickyFooter
						:is-edit="isEdit"
						:disabled-confirm="isSubmitting"
						:button-edit-confirm-text="isSubmitting ? 'Saving...' : 'Save changes'"
						:remove-text="isEdit ? 'Remove pre-order campaign' : null"
						content-class="main-wrapper-large"
						@onConfirm="handleSubmit"
						@onCancel="$router.push({ name: 'PreOrders'})"
						@onRemove="isEdit ? $refs['modal-remove'].open() : null"
					/>
				</form>
			</CCol>
		</CRow>

		<BaseModalConfirmDelete
			ref="modal-remove"
			:handle-remove="deletePreOrder.bind(null, preOrderId)"
			description="Are you sure you want to delete this pre-order campaign?"
			title="Remove this pre-order campaign?"
			is-confirm-required
			@onSuccess="$router.push({ name: 'PreOrders'})"
		/>

		<BaseModalBiOptions
			ref="modal-pickup-at-store-confirm"
			title="Continue to new page?"
			description="To create or edit pickup at stores condition, the page have to be changed and any unsaved changes in a current page will be lost. Save changes before continue to a new page?"
			primary-button-text="Save and continue"
			secondary-button-text="Continue without save"
			@onPrimaryAction="handleSaveThenNavigate"
			@onSecondaryAction="handleNavigateWithoutSave"
		/>

		<BaseModalDialog
			ref="modal-unable-save"
			title="Unable to save"
			description="An unexpected error occurred while saving changes or some required information are invalid or missing. Please check and try again."
		/>
	</div>
</template>

<script>
import { mapActions } from 'vuex';
import PreOrderSidebar from '@/components/PreOrderSidebar.vue';
import PreOrderGeneralInfo from '@/components/PreOrderGeneralInfo.vue';
import PreOrderProductPriceDisplaying from '@/components/PreOrderProductPriceDisplaying.vue';
import PreOrderSchedule from '@/components/PreOrderSchedule.vue';
import PreOrderLimit from '@/components/PreOrderLimit.vue';
import PreOrderDeliveryShipping from '@/components/PreOrderDeliveryShipping.vue';
import PreOrderGrabAndGo from '@/components/PreOrderGrabAndGo.vue';
import PreOrderPickupAtStore from '@/components/PreOrderPickupAtStore.vue';
import PreOrderLabel from '@/components/PreOrderLabel.vue';
import PreOrderCampaignInformation from '@/components/PreOrderCampaignInformation.vue';
import PreOrderTermAndCondition from '@/components/PreOrderTermAndCondition.vue';
import PreOrderSku from '@/components/PreOrderSku.vue';
import PreOrderCustomButton from '@/components/PreOrderCustomButton.vue';
import BaseModalBiOptions from '@/components/BaseModalBiOptions.vue';
import BaseModalDialog from '@/components/BaseModalDialog.vue';

import {
	PREORDER_REFS,
	PREORDER_STATUS_OPTIONS,
	PREORDER_PAYMENT_OPTIONS,
	PREORDER_STATUSES,
	PREORDER_PAYMENT,
} from '../enums/preorders';
import {
	transformedPreOrderProductsToAPI,
	transformedPreOrderShipmentMethodToAPI,
} from '../assets/js/transform/preorders';
import { convertDateTimeToUTC, scrollToTop } from '../assets/js/helpers';

export default {
	name: 'PreOrderForm',
	components: {
		PreOrderSidebar,
		PreOrderGeneralInfo,
		PreOrderProductPriceDisplaying,
		PreOrderSchedule,
		PreOrderLimit,
		PreOrderDeliveryShipping,
		PreOrderGrabAndGo,
		PreOrderPickupAtStore,
		PreOrderLabel,
		PreOrderCampaignInformation,
		PreOrderTermAndCondition,
		PreOrderSku,
		PreOrderCustomButton,
		BaseModalBiOptions,
		BaseModalDialog,
	},
	props: {
		isEdit: {
			type: Boolean,
			default: false,
		},
		preOrderId: {
			type: [Number, String],
			default: null,
		},
		preOrderGeneralInfo: {
			type: Object,
			default: () => ({}),
		},
		preOrderCampaignSku: {
			type: Object,
			default: () => ({}),
		},
		preOrderProductPriceDisplay: {
			type: Object,
			default: () => ({}),
		},
		preOrderScheduleInfo: {
			type: Object,
			default: () => ({}),
		},
		preOrderLimit: {
			type: Object,
			default: () => ({}),
		},
		preOrderDeliveryShippingInfo: {
			type: Object,
			default: () => ({}),
		},
		preOrderLabel: {
			type: Object,
			default: () => ({}),
		},
		preOrderCampaignInfo: {
			type: Object,
			default: () => ({}),
		},
		preOrderTermsAndConditions: {
			type: Object,
			default: () => ({}),
		},
		preOrderPickupAtStore: {
			type: Object,
			default: () => ({}),
		},
		preOrderGrabAndGo: {
			type: Object,
			default: () => ({}),
		},
		preOrderCustomButton: {
			type: Object,
			default: () => ({}),
		},
	},
	data() {
		const generalInfo = this.preOrderGeneralInfo || {};
		const campaignSku = this.preOrderCampaignSku || {};
		const productPriceDisplay = this.preOrderProductPriceDisplay || {};
		const scheduleInfo = this.preOrderScheduleInfo || {};
		const limit = this.preOrderLimit || {};
		const deliveryShippingInfo = this.preOrderDeliveryShippingInfo || {};
		const label = this.preOrderLabel || {};
		const campaignInfo = this.preOrderCampaignInfo || {};
		const termsAndConditions = this.preOrderTermsAndConditions || {};
		const customButton = this.preOrderCustomButton || {};

		return {
			PREORDER_REFS,
			isSubmitting: false,
			isLoadingPaymentMethod: true,
			generalInfo: {
				preOrderName: generalInfo.preOrderName || '',
				preOrderStatus: generalInfo.preOrderStatus || PREORDER_STATUS_OPTIONS.find((option) => option.value === PREORDER_STATUSES.INACTIVE).value,
				preOrderPaymentOption: generalInfo.preOrderPaymentOption || PREORDER_PAYMENT_OPTIONS.find((option) => option.value === PREORDER_PAYMENT.FULL_PRICE_AND_RESERVE).value,
			},
			campaignSku: {
				preOrderSkus: campaignSku.preOrderSkus || [],
				preOrderIsAllowedRestrictProduct: campaignSku.preOrderIsAllowedRestrictProduct ?? false,
				preOrderIsRestrictAllProducts: campaignSku.preOrderIsRestrictAllProducts ?? true,
				preOrderRestrictSkus: campaignSku.preOrderRestrictSkus || [],
			},
			productPriceDisplay: {
				isHideProductPriceDisplay: productPriceDisplay.isHideProductPriceDisplay,
				priceUnavailableDescriptionTh: productPriceDisplay.priceUnavailableDescriptionTh,
				priceUnavailableDescriptionEn: productPriceDisplay.priceUnavailableDescriptionEn,
			},
			scheduleInfo: {
				isTimeCounterVisible: scheduleInfo.isTimeCounterVisible ?? true,
				preOrderStartDate: scheduleInfo.preOrderStartDate || null,
				preOrderStartTime: scheduleInfo.preOrderStartTime || '',
				preOrderEndDate: scheduleInfo.preOrderEndDate || null,
				preOrderEndTime: scheduleInfo.preOrderEndTime || '',
				paymentStartDate: scheduleInfo.paymentStartDate || null,
				paymentStartTime: scheduleInfo.paymentStartTime || '',
				paymentEndDate: scheduleInfo.paymentEndDate || null,
				paymentEndTime: scheduleInfo.paymentEndTime || null,
				periodStatus: scheduleInfo.periodStatus || null,
				remainingStatus: scheduleInfo.remainingStatus || null,
				releasedStatus: scheduleInfo.releasedStatus || null,
				releaseDate: scheduleInfo.releaseDate || null,
				releaseTime: scheduleInfo.releaseTime || '',
				releaseTitleEn: scheduleInfo.releaseTitleEn || null,
				releaseTitleTh: scheduleInfo.releaseTitleTh || null,
				releaseDescriptionTh: scheduleInfo.releaseDescriptionTh || null,
				releaseDescriptionEn: scheduleInfo.releaseDescriptionEn || null,
			},
			limit: {
				limitPerUser: limit.limitPerUser || null,
				limitPerOrder: limit.limitPerOrder || null,
				isOverridePaymentPeriod: limit.isOverridePaymentPeriod ?? false,
				overridePaymentPeriodHourValue: limit.overridePaymentPeriodHourValue,
				overridePaymentPeriodMinuteValue: limit.overridePaymentPeriodMinuteValue,
			},
			deliveryShippingInfo: {
				isAvailable: deliveryShippingInfo.isAvailable ?? true,
				isStandardDeliveryAvailable: deliveryShippingInfo.isStandardDeliveryAvailable ?? true,
				standardDeliveryEn: deliveryShippingInfo.standardDeliveryEn || null,
				standardDeliveryTh: deliveryShippingInfo.standardDeliveryTh || null,
				isExpressDeliveryAvailable: deliveryShippingInfo.isExpressDeliveryAvailable ?? true,
				expressDeliveryEn: deliveryShippingInfo.expressDeliveryEn || null,
				expressDeliveryTh: deliveryShippingInfo.expressDeliveryTh || null,
				paymentMethodSettings: deliveryShippingInfo.paymentMethodSettings || [],
			},
			pickupAtStore: { ...this.preOrderPickupAtStore },
			label: {
				primary: label.primary || [],
			},
			campaignInfo: {
				campaignInformationTh: campaignInfo.campaignInformationTh || '',
				campaignInformationEn: campaignInfo.campaignInformationEn || '',
			},
			termsAndConditions: {
				termsAndConditionsEn: termsAndConditions.termsAndConditionsEn || null,
				termsAndConditionsTh: termsAndConditions.termsAndConditionsTh || null,
				isStoreIdCard: termsAndConditions.isStoreIdCard ?? false,
			},
			grabAndGo: {
				isAvailable: this.preOrderGrabAndGo.isAvailable ?? false,
				branchIds: this.preOrderGrabAndGo.branchIds ?? [],
				paymentMethodSettings: this.preOrderGrabAndGo.paymentMethodSettings ?? [],
			},
			customButton: {
				isActive: Boolean(customButton.isActive),
				buttonNameTh: customButton.buttonNameTh ?? null,
				buttonNameEn: customButton.buttonNameEn ?? null,
				customLink: customButton.customLink ?? null,
			},
		};
	},
	methods: {
		...mapActions({
			createPreOrder: 'preorders/createPreOrder',
			updatePreOrder: 'preorders/updatePreOrder',
			deletePreOrder: 'preorders/deletePreOrder',
		}),
		async scrollToSection(refNames = []) {
			const element = refNames.reduce((result, current) => {
				if (result) {
					return result.$refs[current];
				}
				return this.$refs[current];
			}, null);

			if (element) {
				await this.$nextTick();
				if (element instanceof Element) {
					element.scrollIntoView({ behavior: 'smooth' });
				} else {
					element.$el.scrollIntoView({ behavior: 'smooth' });
				}
			}
		},
		checkFormValidation() {
			const refGeneralInfo = this.$refs[PREORDER_REFS.GENERAL_INFO];
			const refSKU = this.$refs[PREORDER_REFS.PRE_ORDER_SKU];
			const refScheduleInfo = this.$refs[PREORDER_REFS.SCHEDULE];
			const refDeliveryShippingInfo = this.$refs[PREORDER_REFS.DELIVERY_SHIPPING];
			const refTermAndCondition = this.$refs[PREORDER_REFS.TERM_AND_CONDITION];
			const refCustomButton = this.$refs[PREORDER_REFS.CUSTOM_BUTTON];

			refGeneralInfo.$v.$touch();
			refSKU.$v.$touch();
			refScheduleInfo.$v.$touch();
			refDeliveryShippingInfo.$v.$touch();
			refTermAndCondition.$v.$touch();
			refCustomButton.$v.$touch();

			if (refGeneralInfo.$v.$invalid) {
				return false;
			}

			if (refSKU.$v.$invalid) {
				return false;
			}

			if (refScheduleInfo.$v.$invalid) {
				return false;
			}

			if (refDeliveryShippingInfo.$v.$invalid) {
				return false;
			}

			if (refTermAndCondition.$v.$invalid) {
				return false;
			}

			if (refCustomButton.$v.$invalid) {
				return false;
			}

			return true;
		},
		async handleSubmit() {
			const isFormValid = this.checkFormValidation();
			if (!isFormValid) {
				return;
			}

			this.isSubmitting = true;

			const params = {
				name: this.generalInfo.preOrderName,
				is_active: !!this.generalInfo.preOrderStatus,
				payment_type: this.generalInfo.preOrderPaymentOption,
				period_start_at: convertDateTimeToUTC(this.scheduleInfo.preOrderStartDate, this.scheduleInfo.preOrderStartTime),
				period_end_at: convertDateTimeToUTC(this.scheduleInfo.preOrderEndDate, this.scheduleInfo.preOrderEndTime),
				is_countdown_visible: this.scheduleInfo.isTimeCounterVisible,
				released_date: convertDateTimeToUTC(this.scheduleInfo.releaseDate, this.scheduleInfo.releaseTime),
				is_released_date_visible: !!this.scheduleInfo.releaseDate,
				released_title_en: this.scheduleInfo.releaseTitleEn,
				released_title_th: this.scheduleInfo.releaseTitleTh,
				released_description_en: this.scheduleInfo.releaseDescriptionEn,
				released_description_th: this.scheduleInfo.releaseDescriptionTh,
				remaining_start_at: convertDateTimeToUTC(this.scheduleInfo.paymentStartDate, this.scheduleInfo.paymentStartTime),
				remaining_end_at: convertDateTimeToUTC(this.scheduleInfo.paymentEndDate, this.scheduleInfo.paymentEndTime),
				usage_limit: this.limit.limitPerUser || null,
				order_limit: this.limit.limitPerOrder || null,
				payment_expire_hour: this.limit.isOverridePaymentPeriod ? this.limit.overridePaymentPeriodHourValue : null,
				payment_expire_minute: this.limit.isOverridePaymentPeriod ? this.limit.overridePaymentPeriodMinuteValue : null,
				is_store_identity_document: this.termsAndConditions.isStoreIdCard,
				is_delivery_method_available: this.deliveryShippingInfo.isAvailable,
				is_pickup_method_available: true, // Always true because we remove toggle true/false
				is_grab_and_go_method_available: this.grabAndGo.isAvailable,
				terms_and_conditions_en: this.termsAndConditions.termsAndConditionsEn,
				terms_and_conditions_th: this.termsAndConditions.termsAndConditionsTh,
				products: transformedPreOrderProductsToAPI(this.campaignSku.preOrderSkus, this.generalInfo.preOrderPaymentOption),
				is_allowed_restrict_product: this.campaignSku.preOrderIsAllowedRestrictProduct,
				is_restrict_all_products: this.campaignSku.preOrderIsRestrictAllProducts,
				restrict_product_skus: this.campaignSku.preOrderIsRestrictAllProducts ? [] : this.campaignSku.preOrderRestrictSkus,
				is_product_price_display: !this.productPriceDisplay.isHideProductPriceDisplay,
				price_unavailable_description_th: this.productPriceDisplay.priceUnavailableDescriptionTh || null,
				price_unavailable_description_en: this.productPriceDisplay.priceUnavailableDescriptionEn || null,
				scheduled_campaign_info_en: this.campaignInfo.campaignInformationEn || null,
				scheduled_campaign_info_th: this.campaignInfo.campaignInformationTh || null,
				label_ids: this.label.primary ? this.label.primary.map(({ id }) => id) : null,
				shipping_payment_method: transformedPreOrderShipmentMethodToAPI(
					this.deliveryShippingInfo,
					this.pickupAtStore,
					this.grabAndGo,
					this.generalInfo.preOrderPaymentOption,
				),
				is_custom_button_available: this.customButton.isActive,
				custom_button_name_th: this.customButton.buttonNameTh,
				custom_button_name_en: this.customButton.buttonNameEn,
				custom_button_action_link: this.customButton.customLink,
			};

			try {
				if (!this.isEdit) {
					await this.createPreOrder(params);

					this.$router.push({ name: 'PreOrderList' });
				} else {
					await this.updatePreOrder({ id: this.preOrderId, params });
				}
			} finally {
				scrollToTop();
				this.isSubmitting = false;
			}
		},
		async handleSaveThenNavigate() {
			const isFormValid = this.checkFormValidation();

			if (isFormValid) {
				await this.handleSubmit();
				this.goToPreOrderPickupAtStoreCreatePage();
			} else {
				// if unable to save - there are any invalid input
				this.$refs['modal-unable-save'].open();
				scrollToTop();
			}
		},
		handleNavigateWithoutSave() {
			this.goToPreOrderPickupAtStoreCreatePage();
		},
		goToPreOrderPickupAtStoreCreatePage() {
			this.$router.push({
				name: 'PreOrderPickupAtStoreCreate',
				params: { id: this.preOrderId },
			});
		},
	},
};
</script>
