<template>
	<CModal
		:show.sync="isShow"
		:close-on-backdrop="false"
		class="modal-confirm"
		centered
	>
		<template #header-wrapper>
			<ModalHeaderWrapper
				:title="title"
				@onClose="close"
			/>
		</template>
		<template #default>
			<p>{{ description }}</p>
		</template>
		<template #footer>
			<CButton
				:disabled="isDisableSecondaryButton"
				color="tertiary"
				class="mr-3"
				@click="handleSecondaryAction"
			>
				{{ isSecondaryLoading ? secondayButtonLoadingText : secondaryButtonText }}
			</CButton>
			<CButton
				:disabled="isDisablePrimaryButton"
				color="primary"
				@click="handlePrimaryAction"
			>
				{{ isPrimaryLoading ? primaryButtonLoadingText : primaryButtonText }}
			</CButton>
		</template>
	</CModal>
</template>

<script>
import ModalHeaderWrapper from '@/components/ModalHeaderWrapper.vue';

export default {
	name: 'BaseModalBiOptions',
	components: {
		ModalHeaderWrapper,
	},
	props: {
		title: {
			type: String,
			default: null,
		},
		description: {
			type: String,
			default: null,
		},
		isPrimaryLoading: {
			type: Boolean,
			default: false,
		},
		isDisablePrimaryButton: {
			type: Boolean,
			default: false,
		},
		primaryButtonText: {
			type: String,
			default: 'Done',
		},
		primaryButtonLoadingText: {
			type: String,
			default: 'Loading',
		},
		isSecondaryLoading: {
			type: Boolean,
			default: false,
		},
		isDisableSecondaryButton: {
			type: Boolean,
			default: false,
		},
		secondaryButtonText: {
			type: String,
			default: 'Cancel',
		},
		secondaryButtonLoadingText: {
			type: String,
			default: 'Loading',
		},
	},
	data() {
		return {
			isShow: false,
		};
	},
	methods: {
		open() {
			this.isShow = true;
		},
		close() {
			this.isShow = false;
			this.$emit('onClose');
		},
		handlePrimaryAction() {
			this.$emit('onPrimaryAction');
			this.close();
		},
		handleSecondaryAction() {
			this.$emit('onSecondaryAction');
			this.close();
		},
	},
};
</script>
