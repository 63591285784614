<template>
	<div>
		<div class="d-flex align-items-center justify-content-between form-group-switch">
			<h4 class="my-4">Delivery shipping</h4>
			<div class="d-flex align-items-center">
				<CSwitch
					:checked="isAvailable"
					variant="3d"
					size="sm"
					class="switch-custom"
					color="success"
					@update:checked="(value) => $emit('update:isAvailable', value)"
				/>
				<span>{{ isAvailable ? 'Available' : 'Unavailable' }}</span>
			</div>
		</div>
		<div
			v-if="isAvailable"
			class="mb-4"
		>
			<h5 class="typo-body-1" :class="{'is-invalid' :$v.isAvailable.$error}">Shipping method</h5>
			<span
				v-if="$v.isAvailable.$error"
				class="invalid-feedback"
			>
				Have to enable at least 1 shipping method.
			</span>
			<!-- Standard delivery -->
			<div class="d-flex align-items-center justify-content-between form-group-switch">
				<h6 class="my-4 typo-body-2">Standard delivery</h6>
				<div class="d-flex align-items-center">
					<CSwitch
						:checked="isStandardDeliveryAvailable"
						variant="3d"
						size="sm"
						class="switch-custom"
						color="success"
						@update:checked="(value) => $emit('update:isStandardDeliveryAvailable', value)"
					/>
					<span>{{ isStandardDeliveryAvailable ? 'Available' : 'Unavailable' }}</span>
				</div>
			</div>
			<CRow class="delivery-info">
				<CCol md="12">
					<h6 class="typo-body-2">Expected standard delivery date</h6>
					<p class="color-black-45 typo-caption">This information will be displayed in many places on the Studio7 website e.i. product detail, checkout process, and order detail. Leave it blank to hide this information.</p>
				</CCol>
				<CCol md="6">
					<CInput
						v-model.trim="localStandardDeliveryTh"
						:disabled="!isStandardDeliveryAvailable"
						data-test-id="standard-delivery-title-th"
						class="mb-0"
						label="Message (TH)"
						placeholder="สินค้าเริ่มจัดส่งในวันที่"
						@input="(value) => $emit('update:standardDeliveryTh', value)"
					/>
				</CCol>
				<CCol md="6">
					<CInput
						v-model.trim="localStandardDeliveryEn"
						:disabled="!isStandardDeliveryAvailable"
						data-test-id="standard-delivery-title-en"
						class="mb-2"
						label="Message (EN)"
						placeholder="E.g. Expected to ship on"
						@input="(value) => $emit('update:standardDeliveryEn', value)"
					/>
				</CCol>
			</CRow>
			<!-- End standard delivery -->

			<!-- Express delivery -->
			<div class="d-flex align-items-center justify-content-between form-group-switch">
				<h6 class="my-4 typo-body-2">Express delivery</h6>
				<div class="d-flex align-items-center">
					<CSwitch
						:checked="isExpressDeliveryAvailable"
						variant="3d"
						size="sm"
						class="switch-custom"
						color="success"
						@update:checked="(value) => $emit('update:isExpressDeliveryAvailable', value)"
					/>
					<span>{{ isExpressDeliveryAvailable ? 'Available' : 'Unavailable' }}</span>
				</div>
			</div>
			<CRow class="delivery-info mb-4">
				<CCol md="12">
					<h6 class="typo-body-2">Expected express delivery date</h6>
					<p class="color-black-45 typo-caption">This information will be displayed in many places on the Studio7 website e.i. product detail, checkout process, and order detail. Leave it blank to hide this information.</p>
				</CCol>
				<CCol md="6">
					<CInput
						v-model.trim="localExpressDeliveryTh"
						:disabled="!isExpressDeliveryAvailable"
						data-test-id="express-delivery-title-th"
						class="mb-0"
						label="Message (TH)"
						placeholder="สินค้าเริ่มจัดส่งในวันที่"
						@input="(value) => $emit('update:expressDeliveryTh', value)"
					/>
				</CCol>
				<CCol md="6">
					<CInput
						v-model.trim="localExpressDeliveryEn"
						:disabled="!isExpressDeliveryAvailable"
						data-test-id="express-delivery-title-en"
						class="mb-2"
						label="Message (EN)"
						placeholder="E.g. Expected to ship on"
						@input="(value) => $emit('update:expressDeliveryEn', value)"
					/>
				</CCol>
			</CRow>
			<!-- End express delivery -->

			<!-- Payment method -->
			<PreOrderPaymentMethod
				:pre-order-id="preOrderId"
				:pre-order-payment-option="preOrderPaymentOption"
				:payment-method-settings="paymentMethodSettings"
				class="payment-method mt-5"
				@update:paymentMethodSettings="(value) => $emit('update:paymentMethodSettings', value)"
				@onLoaded="$emit('onLoadedPaymentMethod')"
			/>
			<!-- End payment method -->
		</div>
		<hr class="mt-2">
	</div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import PreOrderPaymentMethod from '@/components/PreOrderPaymentMethod.vue';

export default {
	name: 'PreOrderDeliveryShipping',
	components: {
		PreOrderPaymentMethod,
	},
	validations() {
		return {
			isAvailable: {
				isValid: (value, vm) => {
					// If Delivery shipping is Unavailable, valid state is true
					if (!value) {
						return true;
					}

					// If not, check some of Standard and Express is Available, valid state will be true
					return vm.isStandardDeliveryAvailable || vm.isExpressDeliveryAvailable;
				},
			},
		};
	},
	mixins: [validationMixin],
	props: {
		preOrderId: {
			type: [Number, String],
			default: null,
		},
		preOrderPaymentOption: {
			type: String,
			default: '',
		},
		isAvailable: {
			type: Boolean,
			default: true,
		},
		isStandardDeliveryAvailable: {
			type: Boolean,
			default: true,
		},
		standardDeliveryEn: {
			type: String,
			default: null,
		},
		standardDeliveryTh: {
			type: String,
			default: null,
		},
		isExpressDeliveryAvailable: {
			type: Boolean,
			default: true,
		},
		expressDeliveryEn: {
			type: String,
			default: null,
		},
		expressDeliveryTh: {
			type: String,
			default: null,
		},
		paymentMethodSettings: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		const {
			standardDeliveryEn: localStandardDeliveryEn,
			standardDeliveryTh: localStandardDeliveryTh,
			expressDeliveryEn: localExpressDeliveryEn,
			expressDeliveryTh: localExpressDeliveryTh,
		} = this || {};
		return {
			localStandardDeliveryEn,
			localStandardDeliveryTh,
			localExpressDeliveryEn,
			localExpressDeliveryTh,
		};
	},
	mounted() {
		if (!this.isAvailable) {
			this.$emit('onLoadedPaymentMethod', true);
		}
	},
};
</script>

<style lang="scss" scoped>
	.delivery-info {
		background-color: $color-gray-100;
		border-radius: rem(4);
		margin: 0;
		padding: rem(24) rem(12);

		&-description {
			color: $color-black-45;
			font-size: rem($font-size-caption);
		}
	}
</style>
