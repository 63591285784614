<template>
	<CRow class="border rounded-sm border-secondary py-3 mb-3">
		<CCol :md="2">
			<img
				:src="thumbnail"
				class="img-fluid ml-2"
				data-test-id="thumbnail"
			>
		</CCol>
		<CCol :md="10">
			<CRow>
				<CCol md="7">
					<div class="typo-body-2" data-test-id="name">
						{{ name }}
					</div>
					<div
						class="typo-caption color-black-45 mb-2"
						data-test-id="sku"
					>
						{{ sku }}
					</div>
				</CCol>
				<CCol md="5">
					<div class="selling-price">
						<span class="color-black-45">Selling price:</span> {{ priceSelling }}
					</div>
				</CCol>
			</CRow>
			<CRow>
				<CCol class="input-quantity">
					<div class="input-quantity-input">
						<BaseInputNumber
							v-model="localManipulationViews"
							:is-valid="!v.manipulationViews.$error"
							:invalid-feedback="$t('global.error.minimumNumber', { number: 1 })"
							label="Manipulate watching*"
							append-text="Qty."
							text-align="left"
							placeholder="0"
						/>
					</div>
					<div class="input-quantity-input">
						<BaseInputNumber
							v-model="localManipulationSold"
							label="Manipulate sold stock"
							append-text="Qty."
							text-align="left"
							placeholder="0"
						/>
					</div>
					<div class="input-quantity-input">
						<BaseInputNumber
							v-if="!isPaymentOptionFullPriceOnly"
							v-model="localPriceReserve"
							:is-valid="!v.priceReserve.$error"
							:invalid-feedback="$t('global.error.minimumNumber', { number: 1 })"
							:decimal-scale="2"
							label="Reserve price*"
							prepend-text="฿"
							text-align="right"
							placeholder="0.00"
						/>
					</div>
				</CCol>
			</CRow>
		</CCol>
	</CRow>
</template>

<script>
import { IMAGE_PLACEHOLDER_URL } from '../enums/general';
import { PREORDER_PAYMENT } from '../enums/preorders';

export default {
	name: 'PreOrderCampaignSKUListItem',
	props: {
		thumbnail: {
			type: String,
			default: IMAGE_PLACEHOLDER_URL,
		},
		name: {
			type: String,
			default: null,
		},
		sku: {
			type: String,
			default: null,
		},
		priceSelling: {
			type: String,
			default: null,
		},
		manipulationViews: {
			type: [String, Number],
			default: null,
		},
		manipulationSold: {
			type: [String, Number],
			default: null,
		},
		priceReserve: {
			type: [String, Number],
			default: null,
		},
		preOrderPaymentOption: {
			type: String,
			default: '',
		},
		v: {
			type: Object,
			required: true,
		},
	},
	computed: {
		isPaymentOptionFullPriceOnly() {
			return this.preOrderPaymentOption === PREORDER_PAYMENT.FULL_PRICE_ONLY;
		},
		localManipulationViews: {
			get() {
				return this.manipulationViews;
			},
			set(value) {
				this.v.$touch();
				this.$emit('update:manipulationViews', value);
			},
		},
		localManipulationSold: {
			get() {
				return this.manipulationSold;
			},
			set(value) {
				this.v.$touch();
				this.$emit('update:manipulationSold', value);
			},
		},
		localPriceReserve: {
			get() {
				return this.priceReserve;
			},
			set(value) {
				this.v.$touch();
				this.$emit('update:priceReserve', value);
			},
		},
	},
};
</script>

<style lang="scss" scoped>
	::v-deep .input-quantity {
		display: flex;
		padding: 0 rem(8);

		&-input {
			padding: 0 rem(4);
			flex-grow: 1;
			flex-basis: 0;
		}

		.input-group-text {
			// override padding right append text
			padding-right: rem(8) !important;
		}
	}

	.selling-price {
		text-align: right;
		white-space: nowrap;
	}
</style>
