<template>
	<CRow>
		<CCol>
			<h4 class="mb-4">
				General Info
			</h4>
			<CInput
				v-model="$v.localPreOrderName.$model"
				:is-valid="!$v.localPreOrderName.$error && null"
				:invalid-feedback="nameErrors"
				label="Pre-order campaign name (for back office)*"
				placeholder="E.g. iPhone pre-order campaign"
				data-test-id="pre-order-name"
				@input="(value) => $emit('update:preOrderName', value)"
			/>
			<CRow>
				<CCol>
					<div class="form-group mt-2">
						<p class="label label-btn-group">
							Campaign status
						</p>
						<BaseButtonGroup
							v-model="localPreOrderStatus"
							:list="statusOptions"
							data-test-id="pre-order-status"
							@input="(value) => $emit('update:preOrderStatus', value)"
						/>
					</div>
				</CCol>
			</CRow>
			<CRow>
				<CCol>
					<div class="form-group mt-2">
						<p class="label label-btn-group">
							Payment option
						</p>
						<BaseButtonGroup
							v-model="localPreOrderPaymentOption"
							:list="paymentTypeOptions"
							:disabled="isEdit"
							data-test-id="pre-order-payment-options"
							@input="(value) => $emit('update:preOrderPaymentOption', value)"
						/>
					</div>
				</CCol>
			</CRow>
			<hr class="mt-4">
		</CCol>
	</CRow>
</template>

<script>
import { required, maxLength } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import { PREORDER_STATUS_OPTIONS, PREORDER_PAYMENT_OPTIONS, PREORDER_STATUSES, PREORDER_PAYMENT } from '../enums/preorders';

export default {
	name: 'PreOrderGeneralInfo',
	validations: {
		localPreOrderName: {
			required,
			maxLength: maxLength(255),
		},
	},
	mixins: [validationMixin],
	props: {
		isEdit: {
			type: Boolean,
			default: false,
		},
		preOrderName: {
			type: String,
			default: '',
		},
		preOrderStatus: {
			type: Number,
			default: null,
		},
		preOrderPaymentOption: {
			type: String,
			default: '',
		},
	},
	data() {
		const { preOrderName, preOrderStatus, preOrderPaymentOption } = this || {};
		return {
			localPreOrderName: preOrderName,
			localPreOrderStatus: preOrderStatus || PREORDER_STATUS_OPTIONS.find((option) => option.value === PREORDER_STATUSES.INACTIVE).value, // Default as 'inactive'
			localPreOrderPaymentOption: preOrderPaymentOption
			|| PREORDER_PAYMENT_OPTIONS.find((option) => option.value === PREORDER_PAYMENT.FULL_PRICE_AND_RESERVE).value, // Default as 'Reserve and full price'
		};
	},
	computed: {
		statusOptions() {
			return PREORDER_STATUS_OPTIONS
				.filter((option) => option.value !== null)
				.map(({ name, value }) => ({ label: name, value }));
		},
		paymentTypeOptions() {
			return PREORDER_PAYMENT_OPTIONS
				.filter((option) => option.value !== null)
				.map(({ name, value }) => ({ label: name, value }));
		},
		nameErrors() {
			if (!this.$v.localPreOrderName.required) {
				return 'Required Field';
			}

			if (!this.$v.localPreOrderName.maxLength) {
				return 'Maximum name length is 255';
			}

			return null;
		},
	},
};
</script>

<style lang="scss" scoped>
	.label-btn-group {
		margin-bottom: rem(10);
	}
</style>
