<template>
	<CRow>
		<CCol md="12" class="mb-3">
			<h4 class="my-4">Pre-order SKU</h4>
			<CRow class="mb-3">
				<CCol md="9">
					<h6>Campaign SKU*</h6>
					<div class="typo-body-2 color-black-45">
						Select SKU for this pre-order campaign and set related info like reserve price if needed.
					</div>
				</CCol>
				<CCol
					md="3"
					class="text-right"
				>
					<CButton
						color="secondary"
						class="btn btn-secondary"
						@click="$refs['modal-campaign-sku'].open()"
					>
						{{ preOrderSkus.length ? 'Edit' : 'Add' }} SKU
					</CButton>
				</CCol>
			</CRow>
			<CRow>
				<CCol
					v-if="preOrderSkus.length"
					lg="12"
				>
					<ul class="list-unstyled p-0">
						<li
							class=""
							data-test-id="product-group-sku"
						>
							<CRow
								v-for="(vProduct, index) in $v.preOrderSkus.$each.$iter"
								:key="vProduct.sku.$model"
								class="typo-body-2 d-flex px-3 text-decoration-none"
							>
								<CCol md="12">
									<PreOrderCampaignSKUListItem
										:pre-order-payment-option.sync="preOrderPaymentOption"
										:thumbnail="vProduct.thumbnail.$model"
										:name="vProduct.name.$model"
										:sku="vProduct.sku.$model"
										:price-selling="vProduct.priceSelling.$model"
										:manipulation-views="vProduct.manipulationViews.$model"
										:manipulation-sold="vProduct.manipulationSold.$model"
										:price-reserve="vProduct.priceReserve.$model"
										:v="vProduct"
										@update:manipulationViews="(value) => handleInputChange('manipulationViews', value, index)"
										@update:manipulationSold="(value) => handleInputChange('manipulationSold', value, index)"
										@update:priceReserve="(value) => handleInputChange('priceReserve', value, index)"
									/>
								</CCol>
							</CRow>
						</li>
					</ul>
				</CCol>
				<CCol v-else class="mb-3" lg="12">
					<BaseNoItemBanner
						:class="{ 'is-invalid': $v.preOrderSkus.$error }"
						text="There are no SKU for this campaign."
					/>
					<div v-if="$v.preOrderSkus.$error" class="invalid-feedback">
						{{ $t('global.error.required') }}
					</div>
				</CCol>
			</CRow>
		</CCol>
		<CCol md="12" class="mb-4">
			<div class="d-flex align-items-center justify-content-between form-group-switch mb-4">
				<div>
					<h6 class="typo-body-2">Allow other normal SKUs can be bought together with pre-order SKUs</h6>
					<div class="typo-caption-2">Turn it on to allow customer can buy other normal SKUs together with the pre-order products in the same cart.</div>
				</div>
				<div class="d-flex align-self-start align-items-center">
					<CSwitch
						:checked="preOrderIsAllowedRestrictProduct"
						variant="3d"
						size="sm"
						class="switch-custom"
						color="success"
						@update:checked="handleIsAllowedRestrictProduct"
					/>
					<span>{{ preOrderIsAllowedRestrictProduct ? 'On' : 'Off' }}</span>
				</div>
			</div>

			<template v-if="preOrderIsAllowedRestrictProduct">
				<div class="form-group mb-4">
					<BaseButtonGroup
						:key="preOrderIsRestrictAllProducts"
						:value="localPreOrderIsRestrictAllProducts"
						:list="PREORDER_RESTRICT_ALL_PRODUCTS_OPTIONS"
						label
						@input="handleIsRestrictAllProductsChange"
					/>
				</div>

				<template v-if="!preOrderIsRestrictAllProducts">
					<div class="d-flex align-items-center justify-content-between form-group-switch mt-2 mb-3">
						<h6 class="typo-body-2">SKUs that can be bought together with pre-order SKUs*</h6>
						<div class="d-flex align-self-start align-items-center">
							<CButton
								color="secondary"
								class="btn btn-secondary"
								@click="$refs['modal-sku'].open()"
							>
								{{ preOrderRestrictSkus.length ? 'Edit' : 'Add' }} SKU
							</CButton>
						</div>
					</div>

					<IdListText
						v-if="preOrderRestrictSkus.length"
						:list="preOrderRestrictSkus"
					/>
					<template v-else>
						<BaseNoItemBanner
							:class="{ 'is-invalid': $v.preOrderRestrictSkus.$error }"
							text="There are no restrict SKU for this campaign."
						/>
						<div v-if="$v.preOrderRestrictSkus.$error" class="invalid-feedback">
							{{ $t('global.error.required') }}
						</div>
					</template>
				</template>
			</template>

			<hr class="mt-5">
		</CCol>
		<ModalCampaignSKU
			ref="modal-campaign-sku"
			:sku-list="preOrderSkus"
			@onConfirm="(value) => $emit('update:preOrderSkus', value)"
		/>
		<ModalSkuList
			ref="modal-sku"
			:sku-list="preOrderRestrictSkus"
			title="Restrict SKUs"
			@onConfirm="(value) => $emit('update:preOrderRestrictSkus', value)"
		/>
	</CRow>
</template>

<script>
import { required, requiredIf, minValue } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import ModalCampaignSKU from '@/components/ModalCampaignSKU.vue';
import ModalSkuList from '@/components/ModalSkuList.vue';
import PreOrderCampaignSKUListItem from '@/components/PreOrderCampaignSKUListItem.vue';
import IdListText from '@/components/IdListText.vue';

import { PREORDER_PAYMENT, PREORDER_RESTRICT_ALL_PRODUCTS_OPTIONS, PREORDER_RESTRICT_ALL_PRODUCTS_STATUSES } from '../enums/preorders';

export default {
	name: 'PreOrderSku',
	components: {
		ModalCampaignSKU,
		ModalSkuList,
		PreOrderCampaignSKUListItem,
		IdListText,
	},
	validations() {
		return {
			preOrderSkus: {
				required,
				$each: {
					thumbnail: {},
					name: {},
					sku: {},
					priceSelling: {},
					manipulationViews: {
						required,
						minValue: minValue(1),
					},
					manipulationSold: {},
					priceReserve: {
						required: requiredIf(() => !this.isPaymentOptionFullPriceOnly),
						minValue: minValue(1),
					},
				},
			},
			preOrderRestrictSkus: {
				required: requiredIf(() => (this.preOrderIsAllowedRestrictProduct && !this.preOrderIsRestrictAllProducts)),
			},
		};
	},
	mixins: [validationMixin],
	props: {
		preOrderSkus: {
			type: Array,
			default: () => [],
		},
		preOrderIsAllowedRestrictProduct: {
			type: Boolean,
			default: false,
		},
		preOrderIsRestrictAllProducts: {
			type: Boolean,
			default: true,
		},
		preOrderRestrictSkus: {
			type: Array,
			default: () => [],
		},
		preOrderPaymentOption: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			PREORDER_RESTRICT_ALL_PRODUCTS_OPTIONS,
		};
	},
	computed: {
		isPaymentOptionFullPriceOnly() {
			return this.preOrderPaymentOption === PREORDER_PAYMENT.FULL_PRICE_ONLY;
		},
		localPreOrderIsRestrictAllProducts() {
			return this.preOrderIsRestrictAllProducts ? 1 : 0;
		},
	},
	methods: {
		handleInputChange(key, value, index) {
			this.$v.preOrderSkus.$touch();
			this.$set(this.preOrderSkus[index], key, value);
			this.$emit(`update:${key}`, this.preOrderSkus);
		},
		handleIsAllowedRestrictProduct(value) {
			this.$v.preOrderRestrictSkus.$reset();
			this.$emit('update:preOrderIsAllowedRestrictProduct', value);
		},
		handleIsRestrictAllProductsChange(value) {
			this.$v.preOrderRestrictSkus.$reset();
			this.$emit('update:preOrderIsRestrictAllProducts', value === PREORDER_RESTRICT_ALL_PRODUCTS_STATUSES.ALL);
		},
	},
};
</script>

<style lang="scss" scoped>
	.is-invalid {
		border: 1px solid $color-alert;
		border-radius: rem(4);
	}
</style>

