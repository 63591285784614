<template>
	<BaseLoading v-if="isLoading" />
	<div v-else>
		<h5 class="typo-body-1">{{ title }}</h5>
		<CRow class="py-2 px-3">
			<CCol :md="paymentNameColumnSizeClass">
				<div class="label">
					Method
				</div>
			</CCol>
			<CCol v-if="!isPaymentOptionReservePriceOnly" md="2">
				<div class="label text-center">
					Full price
				</div>
			</CCol>
			<CCol v-if="!isPaymentOptionFullPriceOnly" md="2">
				<div class="label text-center">
					Reserve price
				</div>
			</CCol>
			<CCol v-if="!isPaymentOptionFullPriceOnly" md="3">
				<div class="label text-center">
					Remaining price
				</div>
			</CCol>
		</CRow>
		<CRow class="mb-5">
			<CCol>
				<div class="border border-gray-400 rounded pb-3">
					<div class="bg-gray-100">
						<CRow class="py-2">
							<CCol :md="paymentNameColumnSizeClass">
								<div class="label ml-3">
									Select all
								</div>
							</CCol>
							<CCol v-if="!isPaymentOptionReservePriceOnly" md="2" class="text-center">
								<BaseIndeterminateCheckbox
									:checked="isEveryCheckboxChecked('selected_full_price', localPaymentMethodList)"
									:indeterminate="isIndeterminateCheckbox('selected_full_price', localPaymentMethodList)"
									class="d-inline-block"
									@update:checked="handleCheckAll('selected_full_price', $event)"
								/>
							</CCol>
							<CCol v-if="!isPaymentOptionFullPriceOnly" md="2" class="text-center">
								<BaseIndeterminateCheckbox
									:checked="isEveryCheckboxChecked('selected_reserve_price', localPaymentMethodList)"
									:indeterminate="isIndeterminateCheckbox('selected_reserve_price', localPaymentMethodList)"
									class="d-inline-block"
									@update:checked="handleCheckAll('selected_reserve_price', $event)"
								/>
							</CCol>
							<CCol v-if="!isPaymentOptionFullPriceOnly" md="3" class="text-center">
								<BaseIndeterminateCheckbox
									:checked="isEveryCheckboxChecked('selected_remaining_price', localPaymentMethodList)"
									:indeterminate="isIndeterminateCheckbox('selected_remaining_price', localPaymentMethodList)"
									class="d-inline-block"
									@update:checked="handleCheckAll('selected_remaining_price', $event)"
								/>
							</CCol>
						</CRow>
					</div>
					<div
						v-for="(group) in localPaymentMethodList"
						:key="group.id"
						class="typo-label py-2"
					>
						<CRow>
							<CCol :md="paymentNameColumnSizeClass">
								<div class="color-black-45 font-weight-normal ml-4">
									<span class="color-gray-900">{{ group.name }}</span>
								</div>
							</CCol>
							<template v-if="!group.methods || group.methods.length <= 1">
								<CCol
									v-if="!isPaymentOptionReservePriceOnly"
									md="2"
									class="text-center"
								>
									<CInputCheckbox
										:checked.sync="group.selected_full_price"
										class="d-inline-block"
										custom
									/>
								</CCol>
								<CCol
									v-if="!isPaymentOptionFullPriceOnly"
									md="2"
									class="text-center"
								>
									<CInputCheckbox
										:checked.sync="group.selected_reserve_price"
										class="d-inline-block"
										custom
									/>
								</CCol>
								<CCol
									v-if="!isPaymentOptionFullPriceOnly"
									md="3"
									class="text-center"
								>
									<CInputCheckbox
										:checked.sync="group.selected_remaining_price"
										class="d-inline-block"
										custom
									/>
								</CCol>
							</template>
							<CCol v-else md="12" class="mt-1">
								<CRow
									v-for="(method) in group.methods"
									:key="method.id"
									class="mt-2 mb-2"
								>
									<CCol :md="paymentNameColumnSizeClass">
										<div class="ml-3 color-black-45 font-weight-normal ml-5">
											{{ method.name }}
										</div>
									</CCol>
									<CCol
										v-if="!isPaymentOptionReservePriceOnly"
										md="2"
										class="text-center"
									>
										<CInputCheckbox
											:checked.sync="method.selected_full_price"
											class="d-inline-block"
											custom
										/>
									</CCol>
									<CCol
										v-if="!isPaymentOptionFullPriceOnly"
										md="2"
										class="text-center"
									>
										<CInputCheckbox
											:checked.sync="method.selected_reserve_price"
											class="d-inline-block"
											custom
										/>
									</CCol>
									<CCol
										v-if="!isPaymentOptionFullPriceOnly"
										md="3"
										class="text-center"
									>
										<CInputCheckbox
											:checked.sync="method.selected_remaining_price"
											class="d-inline-block"
											custom
										/>
									</CCol>
								</CRow>
							</CCol>
						</CRow>
					</div>
				</div>
			</CCol>
		</CRow>
	</div>
</template>

<script>
import isEmpty from 'lodash/isEmpty';
import { mapState, mapActions, mapGetters } from 'vuex';

import { transformPaymentMethodSelector, transformSelectedPreOrderPaymentList } from '../assets/js/transform/payments';
import { isIndeterminateCheckbox, isEveryCheckboxChecked, handleCheckboxSelectedAll, removeDuplicateField } from '../assets/js/helpers';

import {
	PREORDER_PAYMENT,
	PREORDER_PAYMENT_METHOD_KEY,
} from '../enums/preorders';

export default {
	name: 'PreOrderPaymentMethod',
	props: {
		preOrderId: {
			type: [Number, String],
			default: null,
		},
		preOrderPaymentOption: {
			type: String,
			default: '',
		},
		paymentMethodSettings: {
			type: Array,
			default: () => [],
		},
		title: {
			type: String,
			default: 'Payment method',
		},
	},
	data() {
		return {
			isLoading: true,
			localPaymentMethodList: [],
			isIndeterminateCheckbox,
			isEveryCheckboxChecked,
			handleCheckboxSelectedAll,
		};
	},
	computed: {
		...mapState('payments', {
			paymentPreOrder: 'preOrder',
		}),
		...mapGetters({
			paymentMethodGroupList: 'payments/paymentMethodGroupList',
			paymentMethodGroupDetails: 'payments/paymentMethodGroupDetails',
		}),
		isPaymentOptionFullPriceOnly() {
			return this.preOrderPaymentOption === PREORDER_PAYMENT.FULL_PRICE_ONLY;
		},
		isPaymentOptionReservePriceOnly() {
			return this.preOrderPaymentOption === PREORDER_PAYMENT.RESERVE_ONLY;
		},
		paymentNameColumnSizeClass() {
			if (this.isPaymentOptionFullPriceOnly) {
				return '10';
			}

			if (this.isPaymentOptionReservePriceOnly) {
				return '7';
			}

			return '5';
		},
	},
	watch: {
		localPaymentMethodList: {
			immediate: false,
			deep: true,
			handler(value) {
				if (!value || value.length <= 0) {
					return;
				}

				const flatPaymentTypeList = value.reduce((acc, cur) => {
					// If there are any methods in group
					// Will ignore parameter of payment group
					const group = isEmpty(cur.methods)
						? [
							{
								payment_method_group_id: cur.id,
								payment_type: this.getPaymentTypeList(cur),
							},
						]
						: [];

					const methods = cur.methods
						? cur.methods
							.map((method) => ({
								payment_method_id: method.id,
								payment_type: this.getPaymentTypeList(method),
							}))
							// Get only method that have at lease one payment_type
							.filter((method) => (method.payment_type && method.payment_type.length))
						: [];

					return [
						...acc,
						...group,
						...methods,
					];
				}, []);

				this.$emit('update:paymentMethodSettings', flatPaymentTypeList);
			},
		},
	},
	async created() {
		const preOrderId = this.preOrderId ?? 0; // id = 0 instead of null when create stage
		const hasPaymentListData = this.paymentPreOrder.paymentList.length > 0 && this.paymentPreOrder.preOrderId === preOrderId;

		if (!hasPaymentListData) {
			await this.getPreOrderPaymentMethodGroups({ preOrderId });
			const groups = [...this.paymentMethodGroupList];

			// Get payment group and sub payment method
			const groupMethods = await Promise.all(groups.map(async (group) => {
				await this.getPaymentMethodGroup(group.id);
				return this.paymentMethodGroupDetails;
			}));

			// Save to store
			this.setPreOrderPaymentList(groupMethods);

			const transformedPaymentMethodList = groupMethods.map((method) => {
				return transformPaymentMethodSelector(method, transformSelectedPreOrderPaymentList, this.paymentMethodSettings);
			});

			this.localPaymentMethodList = removeDuplicateField(transformedPaymentMethodList, 'id');
		} else {
			const transformedPaymentMethodList = this.paymentPreOrder.paymentList.map((method) => {
				return transformPaymentMethodSelector(method, transformSelectedPreOrderPaymentList, this.paymentMethodSettings);
			});

			this.localPaymentMethodList = removeDuplicateField(transformedPaymentMethodList, 'id');
		}

		this.isLoading = false;
		this.$emit('onLoaded');
	},
	methods: {
		...mapActions({
			getPreOrderPaymentMethodGroups: 'payments/getPreOrderPaymentMethodGroups',
			getPaymentMethodGroup: 'payments/getPaymentMethodGroup',
			setPreOrderPaymentList: 'payments/setPreOrderPaymentList',
		}),
		getPaymentTypeList(method) {
			const paymentTypes = [
				method.selected_full_price ? PREORDER_PAYMENT_METHOD_KEY.FULL_PRICE : null,
				method.selected_reserve_price ? PREORDER_PAYMENT_METHOD_KEY.RESERVE_PRICE : null,
				method.selected_remaining_price ? PREORDER_PAYMENT_METHOD_KEY.REMAINING_PRICE : null,
			];

			return paymentTypes.filter((payment) => (payment));
		},
		handleCheckAll(checkboxKey, isCheck = false) {
			this.localPaymentMethodList = handleCheckboxSelectedAll(checkboxKey, this.localPaymentMethodList, isCheck);
		},
	},
};
</script>

<style lang="scss" scoped>
	.label {
		white-space: nowrap;
	}
</style>
