<template>
	<CRow>
		<CCol>
			<h4 class="my-4">
				Term and condition
			</h4>
			<div class="d-flex align-items-start justify-content-between form-group-switch">
				<div class="mb-2">
					<h6 class="typo-body-2 mb-0">Collect customer ID card number</h6>
					<p class="description typo-helper-text color-black-45">
						Turn it on to require an ID card from the customer when they want to<br> pre-order the product. ID card number will store in back-office system<br> and customer’s order.
					</p>
				</div>
				<div class="d-flex align-items-center">
					<CSwitch
						:checked.sync="termsAndConditions.isStoreIdCard"
						variant="3d"
						size="sm"
						class="switch-custom"
						color="success"
						@update:checked="handleUpdateCustomerId"
					/>
					<span>{{ termsAndConditions.isStoreIdCard ? 'On' : 'Off' }}</span>
				</div>
			</div>
			<CRow>
				<CCol>
					<label>Pre-order term and condition* (TH)</label>
					<BaseRichTextEditor
						v-model="$v.termsAndConditions.termsAndConditionsTh.$model"
						:is-valid="!$v.termsAndConditions.termsAndConditionsTh.$error"
						invalid-feedback="Pre-order terms and conditions (TH) is required"
						data-test-id="terms-and-conditions-th"
						name="contentTH"
						@input="(value) => $emit('update:termsAndConditionsTh', value)"
					/>
				</CCol>
			</CRow>
			<CRow>
				<CCol>
					<label>Pre-order instruction (EN)</label>
					<BaseRichTextEditor
						v-model="termsAndConditions.termsAndConditionsEn"
						data-test-id="terms-and-conditions-en"
						name="contentTH"
						@input="(value) => $emit('update:termsAndConditionsEn', value)"
					/>
				</CCol>
			</CRow>
			<hr class="mt-4">
		</CCol>
	</CRow>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';

export default {
	name: 'PreOrderTermAndCondition',
	validations() {
		let schema = {};
		const base = {
			termsAndConditionsTh: {
				required,
			},
		};

		schema = {
			termsAndConditions: {
				...base,
			},
		};

		return schema;
	},
	mixins: [validationMixin],
	props: {
		termsAndConditionsEn: {
			type: String,
			default: null,
		},
		termsAndConditionsTh: {
			type: String,
			default: null,
		},
		isStoreIdCard: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			termsAndConditions: {
				termsAndConditionsEn: this.termsAndConditionsEn,
				termsAndConditionsTh: this.termsAndConditionsTh,
				isStoreIdCard: this.isStoreIdCard,
			},
		};
	},
	methods: {
		handleUpdateCustomerId(value) {
			this.$emit('update:isStoreIdCard', value);
		},
	},
};
</script>

<style lang="scss" scoped>
	::v-deep .ql-container {
		height: rem(200);
	}
</style>
