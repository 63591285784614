<template>
	<div>
		<div class="d-flex justify-content-between my-4">
			<div>
				<h4>Custom Button</h4>
				<div class="typo-body-2 color-black-45">Customer button name and url link on in pdp page.</div>
			</div>
			<div class="d-flex align-items-center">
				<CSwitch
					:checked="formData.isActive"
					variant="3d"
					size="sm"
					class="switch-custom"
					color="success"
					@update:checked="toggleSwitch"
				/>
				<span class="px-3">{{ formData.isActive ? 'Available' : 'Unavailable' }}</span>
			</div>
		</div>
		<CRow v-if="formData.isActive" class="mt-3">
			<CCol col="6">
				<CInput
					v-model.trim="$v.formData.buttonNameTh.$model"
					:is-valid="!$v.formData.buttonNameTh.$error && null"
					:invalid-feedback="$t('global.error.required')"
					placeholder="Button Name (TH)"
					label="Button Name (TH) *"
				/>
			</CCol>
			<CCol col="6">
				<CInput
					v-model.trim="$v.formData.buttonNameEn.$model"
					:is-valid="!$v.formData.buttonNameEn.$error && null"
					:invalid-feedback="$t('global.error.required')"
					placeholder="Button Name (EN)"
					label="Button Name (EN) *"
				/>
			</CCol>
			<CCol col="12">
				<CInput
					v-model.trim="$v.formData.customLink.$model"
					:is-valid="!$v.formData.customLink.$error && null"
					:invalid-feedback="errorCustomLink"
					description="custom link for PLP example : https://www.studio7thailand.com/th"
					label="Custom Link *"
				/>
			</CCol>
		</CRow>
	</div>
</template>

<script>
import { url, requiredIf } from 'vuelidate/lib/validators';
import { scrollToBottom } from '../assets/js/helpers';

export default {
	name: 'PreOrderCustomButton',
	props: {
		formData: {
			type: Object,
			default: null,
		},
	},
	validations() {
		return {
			formData: {
				buttonNameTh: {
					required: requiredIf(() => this.formData.isActive),
				},
				buttonNameEn: {
					required: requiredIf(() => this.formData.isActive),
				},
				customLink: {
					required: requiredIf(() => this.formData.isActive),
					url,
				},
			},

		};
	},
	computed: {
		errorCustomLink() {
			if (!this.$v.formData.customLink.$model) {
				return this.$t('global.error.required');
			}
			return this.$t('global.error.url');
		},
	},
	methods: {
		toggleSwitch(status) {
			this.formData.isActive = status;
			if (!this.formData.isActive) {
				this.clearData();
			}
			this.$nextTick(() => {
				scrollToBottom();
			});
		},
		clearData() {
			this.formData.buttonNameTh = null;
			this.formData.buttonNameEn = null;
			this.formData.customLink = null;
		},
	},
};
</script>
