<template>
	<CRow>
		<CCol>
			<div class="d-flex align-items-center justify-content-between form-group-switch">
				<h4 class="my-4">
					Pickup at stores
				</h4>
			</div>
			<template v-if="isEdit">
				<!-- Rules -->
				<PreOrderPickupAtStoreRules
					:is-editable="isEditable"
					:pre-order-id="preOrderId"
					:rules="rules"
					@onCreateConditionClick="$emit('onCreateConditionClick')"
				/>
				<!-- End Rules -->

				<!-- Payment method -->
				<BaseLoading v-if="isLoadingPaymentMethod" />
				<PreOrderPaymentMethod
					v-else
					:pre-order-id="preOrderId"
					:pre-order-payment-option="preOrderPaymentOption"
					:payment-method-settings="paymentMethodSettings"
					title="Payment method (pickup at stores)"
					class="payment-method mt-5 mb-4"
					@update:paymentMethodSettings="(value) => $emit('update:paymentMethodSettings', value)"
				/>
				<!-- End payment method -->
			</template>
			<div v-else class="d-flex alert alert-warning mb-5" role="alert">
				<CIcon class="icon-warning" name="cil-warning" />
				<p class="typo-body-2 mb-0">Will be able to enable this shipping type and set pick up condition after pre-order campaign has been created.</p>
			</div>
			<hr class="mt-2">
		</CCol>
	</CRow>
</template>

<script>
import PreOrderPickupAtStoreRules from '@/components/PreOrderPickupAtStoreRules.vue';
import PreOrderPaymentMethod from '@/components/PreOrderPaymentMethod.vue';

export default {
	name: 'PreOrderPickupAtStore',
	components: {
		PreOrderPickupAtStoreRules,
		PreOrderPaymentMethod,
	},
	props: {
		isEdit: {
			type: Boolean,
			default: false,
		},
		preOrderId: {
			type: [Number, String],
			default: null,
		},
		rules: {
			type: Array,
			default: () => [],
		},
		isEditable: {
			type: Boolean,
			default: true,
		},
		preOrderPaymentOption: {
			type: String,
			default: '',
		},
		paymentMethodSettings: {
			type: Array,
			default: () => [],
		},
		isLoadingPaymentMethod: {
			type: Boolean,
			default: true,
		},
	},
};
</script>

<style lang="scss" scoped>
	.alert {
		padding: rem(16);

		// .alert-warning
		&-warning {
			background-color: $color-primary-10;
			border-color: $color-primary-10;
		}

		.icon-warning {
			color: $color-primary;
			height: rem(24);
			width: rem(24);

			margin-right: rem(16);
		}
	}
</style>
