<template>
	<CRow>
		<CCol md="12">
			<h2 class="typo-h4">
				Product price displaying
			</h2>
		</CCol>
		<CCol md="12">
			<div class="d-flex align-items-center justify-content-between form-group-switch my-4">
				<div>
					<h6 class="typo-body-2">Hide product price until pre-order period started</h6>
					<div class="typo-caption-2">Turn it on to hide all product prices in the campaign until pre-order period has started.</div>
				</div>
				<div class="d-flex align-self-start align-items-center">
					<CSwitch
						:checked="isHideProductPriceDisplay"
						variant="3d"
						size="sm"
						class="switch-custom"
						color="success"
						@update:checked="handleIsHideProductPriceDisplay"
					/>
					<span>{{ isHideProductPriceDisplay ? 'On' : 'Off' }}</span>
				</div>
			</div>
		</CCol>
		<CCol md="12" class="mt-3 mb-3">
			<h6 class="typo-body-2">Message when price unavailable</h6>
			<CRow class="mb-2">
				<CCol md="3">
					<img
						src="/assets/images/release-info-annotation-2.svg"
						alt="release info"
					>
				</CCol>
				<CCol md="8">
					<p class="typo-caption-2">
						The message below will be displayed below a product description when product price unavailable for the customer (Admin hide the price or price issue from ITEC). Leave it blank to hide this message.
					</p>
				</CCol>
			</CRow>
		</CCol>
		<CCol md="6">
			<CInput
				v-model.trim="localPriceUnavailableDescriptionTh"
				class="mb-0"
				label="Price unavailable message (TH)"
				placeholder="E.g. ประกาศราคาเร็ว ๆ นี้"
				@input="handlePriceUnavailableDescriptionTh"
			/>
		</CCol>
		<CCol md="6">
			<CInput
				v-model.trim="localPriceUnavailableDescriptionEn"
				class="mb-2"
				label="Price unavailable message (EN)"
				placeholder="E.g. Price will be available soon"
				@input="handlePriceUnavailableDescriptionEn"
			/>
		</CCol>
		<CCol md="12" class="pt-2">
			<hr class="mt-4">
		</CCol>
	</CRow>
</template>

<script>
export default {
	name: 'PreOrderProductPriceDisplaying',
	props: {
		isHideProductPriceDisplay: {
			type: Boolean,
			default: false,
		},
		priceUnavailableDescriptionTh: {
			type: String,
			default: '',
		},
		priceUnavailableDescriptionEn: {
			type: String,
			default: '',
		},
	},
	data() {
		const {
			isHideProductPriceDisplay,
			priceUnavailableDescriptionTh,
			priceUnavailableDescriptionEn,
		} = this || {};
		return {
			localIsHideProductPriceDisplay: isHideProductPriceDisplay,
			localPriceUnavailableDescriptionTh: priceUnavailableDescriptionTh,
			localPriceUnavailableDescriptionEn: priceUnavailableDescriptionEn,
		};
	},
	methods: {
		handleIsHideProductPriceDisplay(value) {
			this.$emit('update:isHideProductPriceDisplay', value);
		},
		handlePriceUnavailableDescriptionTh(value) {
			this.$emit('update:priceUnavailableDescriptionTh', value);
		},
		handlePriceUnavailableDescriptionEn(value) {
			this.$emit('update:priceUnavailableDescriptionEn', value);
		},
	},
};
</script>
