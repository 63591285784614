<template>
	<CRow>
		<CCol>
			<h4 class="my-4">
				Campaign information
			</h4>
		</CCol>
		<CCol md="12" class="mb-3">
			<CRow class="mb-2">
				<CCol md="3">
					<img
						src="/assets/images/release-info-annotation-3.svg"
						alt="release info"
					>
				</CCol>
				<CCol md="8">
					<p class="typo-caption-2">Display addition information about the campaign for customer, this message will be displayed below a product variant selection. Leave it blank to hide this message.</p>
				</CCol>
			</CRow>
			<CardDescriptionInfo
				class="cart-visibility-description mt-3 mb-0"
				description="this message will be displayed until pre-order period has started"
			/>
		</CCol>
		<CCol md="12">
			<label>Campaign information (TH)</label>
			<BaseRichTextEditor
				:value="localCampaignInformationTh"
				name="contentTH"
				@input="handleCampaignInformationThChange"
			/>
		</CCol>
		<CCol md="12">
			<label>Campaign information (EN)</label>
			<BaseRichTextEditor
				:value="localCampaignInformationEn"
				name="contentEN"
				@input="handleCampaignInformationEnChange"
			/>
		</CCol>
		<CCol>
			<hr class="mt-4">
		</CCol>
	</CRow>
</template>

<script>
import CardDescriptionInfo from '@/components/CardDescriptionInfo.vue';

export default {
	name: 'PreOrderCampaignInformation',
	components: {
		CardDescriptionInfo,
	},
	props: {
		campaignInformationEn: {
			type: String,
			default: null,
		},
		campaignInformationTh: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			localCampaignInformationEn: this.campaignInformationEn,
			localCampaignInformationTh: this.campaignInformationTh,
		};
	},
	methods: {
		handleCampaignInformationEnChange(value) {
			this.$emit('update:campaignInformationEn', value);
		},
		handleCampaignInformationThChange(value) {
			this.$emit('update:campaignInformationTh', value);
		},
	},
};
</script>

<style lang="scss" scoped>
	.alert {
		padding: rem(16);

		// .alert-warning
		&-warning {
			background-color: $color-info-10;
			border-color: $color-info-10;
		}

		.icon-info {
			color: $color-info;
			height: rem(24);
			width: rem(24);

			margin-right: rem(16);
		}
	}
</style>
