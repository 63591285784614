<template>
	<CCol sm="4">
		<nav class="side-bar-nav text-color-black bg-white rounded-sm shadow-sm p-3">
			<div
				v-for="refKey in Object.keys(PREORDER_REFS)"
				:key="refKey"
				class="nav-item p-2"
				@click.prevent="handleClickNav(PREORDER_REFS[refKey])"
			>
				{{ PREORDER_REFS_TITLE[PREORDER_REFS[refKey]] }}
			</div>
		</nav>
	</CCol>
</template>

<script>
import { PREORDER_REFS, PREORDER_REFS_TITLE } from '../enums/preorders';

export default {
	name: 'PreOrderSidebar',

	data() {
		return {
			PREORDER_REFS,
			PREORDER_REFS_TITLE,
		};
	},
	methods: {
		handleClickNav(refNames) {
			this.$emit('onClickNav', refNames);
		},
	},
};
</script>


<style lang="scss" scoped>
	.nav-item {
		cursor: pointer;
		font-size: rem(14);

		&:hover {
			background-color: $color-gray-100;
			border-radius: rem(4);
			color: $color-black-90;
			font-weight: 600;
		}
	}

	.side-bar-nav {
		position: sticky;
		top: rem(12);
		overflow-x: hidden;
		overflow-y: auto;
		max-width: rem(270);
		max-height: calc(100vh - 220px);
		width: 100%;
	}
</style>
