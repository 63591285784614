<template>
	<div>
		<div class="d-flex align-items-center justify-content-between form-group-switch mb-3">
			<div>
				<h6>Pickup at stores and serving capacity</h6>
				<div class="typo-body-2 color-black-45">
					Set available branch for pickup at stores and serving capacity.
				</div>
			</div>
			<div
				v-if="isEditable"
				class="text-right align-self-start"
			>
				<CButton
					color="secondary"
					class="btn btn-secondary text-nowrap"
					@click.prevent="handleCreateCondition"
				>
					<CIcon class="mr-2" name="cil-plus" /> Create
				</CButton>
			</div>
		</div>

		<div v-if="rules.length">
			<div class="d-flex d-flex store-list-header">
				<div class="label col-rule-name">
					Rule Name
				</div>
				<div class="label col-total-available-branches">
					Total available branches
				</div>
				<div class="label col-actions"></div>
			</div>
			<div class="d-flex store-list">
				<ul
					v-for="rule in rules"
					:key="rule.id"
				>
					<li class="list-item typo-body-2 mb-3">
						{{ rule.name }}
					</li>
					<li class="list-item col-total-available-branches typo-body-2 mb-3">
						Total {{ rule.branchQuantity }} branch(s)
					</li>
					<li class="list-item col-actions">
						<router-link
							:to="{
								name: ROUTE_NAME.PRE_ORDER_PICKUP_AT_STORE_EDIT,
								params: {
									id: preOrderId,
									pickupId: rule.id,
								}
							}"
							class="icon-edit align-self-center"
						>
							<CIcon name="cil-pencil" />
						</router-link>
					</li>
				</ul>
			</div>
		</div>
		<div v-else>
			<BaseNoItemBanner
				text="There are no pickup at stores codition for the campaign"
			/>
		</div>
	</div>
</template>

<script>
import { ROUTE_NAME } from '../enums/route';

export default {
	name: 'PreOrderPickupAtStoreRules',
	props: {
		isEditable: {
			type: Boolean,
			default: true,
		},
		preOrderId: {
			type: [String, Number],
			default: '',
		},
		rules: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			ROUTE_NAME,
		};
	},
	methods: {
		handleCreateCondition() {
			this.$emit('onCreateConditionClick');
		},
	},
};
</script>

<style lang="scss" scoped>
	.store-list {
		overflow-y: auto;
		min-height: rem(60);
		padding: rem(20) rem(16) rem(8);
		border-radius: rem(4);
		border: solid 1px $color-gray-400;
		flex-direction: column;

		ul {
			list-style: none;
			padding: 0;
			margin: 0;
			display: flex;
		}

		.list-item {
			color: $color-black-45;
			display: flex;
			width: 40%;

			&.col-total-available-branches {
				width: 50%;
			}

			&.col-actions {
				justify-content: flex-end;
				width: 10%;
			}
		}

		.icon-edit {
			margin-top: rem(-12);
			color: $color-gray-400;

			&:hover {
				color: $color-orange;
			}
		}

		// .store-list-header
		&-header {
			font-size: rem(12);
			padding: 0 rem(16);

			.label {
				font-size: rem(13);
				margin-bottom: rem(8);
				width: 40%;

				&.col-total-available-branches {
					width: 50%;
				}

				&.col-actions {
					width: 10%;
				}
			}
		}
	}
</style>
