<template>
	<div>
		<div class="d-flex align-items-center justify-content-between form-group-switch">
			<h4 class="my-4">Grab and go</h4>
			<div class="d-flex align-items-center">
				<CSwitch
					:checked="isAvailable"
					variant="3d"
					size="sm"
					class="switch-custom"
					color="success"
					@update:checked="(value) => $emit('update:isAvailable', value)"
				/>
				<span>{{ isAvailable ? 'Available' : 'Unavailable' }}</span>
			</div>
		</div>

		<template v-if="isAvailable">
			<!-- Available branch -->
			<AvailableBranch
				:branch-ids="branchIds"
				class="pre-order-branches"
				is-editable
				@update:branchIds="(value) => $emit('update:branchIds', value)"
			/>
			<!-- End Available branch -->

			<!-- Payment method -->
			<BaseLoading v-if="isLoadingPaymentMethod" />
			<PreOrderPaymentMethod
				v-else
				:pre-order-id="preOrderId"
				:pre-order-payment-option="preOrderPaymentOption"
				:payment-method-settings="paymentMethodSettings"
				title="Payment method (grab and go)"
				class="payment-method mt-5 mb-4"
				@update:paymentMethodSettings="(value) => $emit('update:paymentMethodSettings', value)"
			/>
			<!-- End payment method -->
		</template>

		<hr class="mt-2">
	</div>
</template>

<script>
import AvailableBranch from '@/components/AvailableBranch.vue';
import PreOrderPaymentMethod from '@/components/PreOrderPaymentMethod.vue';

export default {
	name: 'PreOrderGrabAndGo',
	components: {
		AvailableBranch,
		PreOrderPaymentMethod,
	},
	props: {
		preOrderId: {
			type: [String, Number],
			default: '',
		},
		isAvailable: {
			type: Boolean,
			default: true,
		},
		branchIds: {
			type: Array,
			default: () => [],
		},
		isLoadingPaymentMethod: {
			type: Boolean,
			default: true,
		},
		preOrderPaymentOption: {
			type: String,
			default: '',
		},
		paymentMethodSettings: {
			type: Array,
			default: () => [],
		},
	},
};
</script>

<style lang="scss" scoped>
	.alert {
		padding: rem(16);

		// .alert-warning
		&-warning {
			background-color: $color-primary-10;
			border-color: $color-primary-10;
		}

		.icon-warning {
			color: $color-primary;
			height: rem(24);
			width: rem(24);

			margin-right: rem(16);
		}
	}

	.pre-order-branches {
		::v-deep {
			.title {
				@include typo-body-1;

				margin: 0;
			}
		}
	}
</style>
