<template>
	<div data-test-id="sku-modal">
		<CForm class="d-flex align-items-start mb-3" @submit.prevent="handleSubmit">
			<div class="flex-fill">
				<CInput
					v-model.trim="inputSKU"
					class="form-sku-input"
					:disabled="disabled"
					:is-valid="!isNotFound && !isDuplicated && !isUnavailable && isValid && null"
					:label="label"
					:placeholder="placeholder"
					:description="$t('global.separateBySemicolon')"
					add-input-classes="form-get-sku-input"
					data-test-id="input-sku"
					@input="handleChange"
				>
					<template #invalid-feedback>
						<div
							v-if="isNotFound"
							class="invalid-feedback"
							data-test-id="error-item-not-found"
						>
							We can't find this SKU in the system
						</div>
						<div
							v-else-if="isDuplicated"
							class="invalid-feedback"
							data-test-id="error-item-duplicated"
						>
							This SKU is already added, please try another.
						</div>
						<div
							v-else-if="isUnavailable"
							class="invalid-feedback"
							data-test-id="error-item-unavailable"
						>
							This SKU has already been used in other pre-order campaign
						</div>
						<div
							v-else-if="!isValid"
							class="invalid-feedback"
							data-test-id="error-item-invalid"
						>
							{{ invalidFeedback }}
						</div>
					</template>
				</CInput>
				<div v-if="description" class="typo-caption color-black-45 mb-2">
					{{ description }}
				</div>
			</div>
			<CButton
				:disabled="disabled || isSubmitting"
				type="submit"
				class="btn-add ml-2"
				color="secondary"
				data-test-id="add-sku-btn"
			>
				Add SKU
			</CButton>
		</CForm>
		<div v-if="isMultipleError" class="error-multiple d-flex mb-3 p-2">
			<CIcon
				class="icon-x-circle color-alert m-1"
				name="cil-x-circle"
			/>
			<span class="typo-caption ml-1 p-1">Can't add some SKUs because they have already been used in other pre-order campaign or don’t exist in the system.</span>
		</div>
		<slot
			v-if="!skuList.length"
			name="no-item"
		>
		</slot>
		<ul
			v-else
			class="list-item"
			:class="{ 'show-border': hasBorder }"
		>
			<li
				v-for="item in skuList"
				:key="item.id"
				class="col m-0 p-0"
			>
				<SKUListItem
					:thumbnail="item.thumbnail"
					:name="item.name"
					:sku="item.sku"
					@onRemove="handleRemove(item.sku)"
				/>
			</li>
		</ul>
	</div>
</template>

<script>
import { getPreOrderProductBySKUAPI } from '../services/api/preorders.api';
import { transformedProduct } from '../assets/js/transform/products';
import SKUListItem from './SKUListItem.vue';

export default {
	name: 'FormGetPreOrderProductBySKU',
	components: {
		SKUListItem,
	},
	props: {
		label: {
			type: String,
			default: null,
		},
		skuList: {
			type: Array,
			default: () => [],
		},
		hasBorder: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		isValid: {
			type: Boolean,
			default: true,
		},
		invalidFeedback: {
			type: String,
			default: null,
		},
		placeholder: {
			type: String,
			default: 'Paste or type SKU number here',
		},
		description: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			isSubmitting: false,
			isNotFound: false,
			isDuplicated: false,
			isUnavailable: false,
			isMultipleError: false,
			inputSKU: '',
		};
	},
	computed: {
		skuArray() {
			if (this.skuList && this.skuList.length) {
				return this.skuList.map(({ sku }) => sku);
			}

			return [];
		},
	},
	methods: {
		resetState() {
			this.inputSKU = '';
			this.isNotFound = false;
			this.isDuplicated = false;
			this.isUnavailable = false;
			this.isMultipleError = false;
		},
		handleChange() {
			this.isNotFound = false;
			this.isDuplicated = false;
			this.isUnavailable = false;
			this.isMultipleError = false;
		},
		handleRemove(sku) {
			this.resetState();
			const foundProduct = this.skuList.find((product) => product.sku === sku);
			this.$emit('onRemove', foundProduct);
		},
		async handleSubmit() {
			// Checking duplicate for a sku
			const exists = this.skuList.find((product) => product.sku.toUpperCase() === this.inputSKU.toUpperCase());
			if (exists) {
				this.isDuplicated = true;
				return;
			}

			this.isNotFound = false;
			this.isDuplicated = false;
			this.isUnavailable = false;
			this.isMultipleError = false;
			this.isSubmitting = true;

			try {
				let list = this.inputSKU.split(';');
				const isMulipleSKUsMode = list && list.length > 1;
				// trim all items and remove null
				list = list.map((item) => item.trim()).filter((item) => item);
				// Unique array && get only sku which not exist in current list
				list = [...new Set(list)].filter((sku) => !this.skuArray.includes(sku));

				if (list && list.length) {
					// Get product(s) information
					await Promise.all(list.map(async (sku) => {
						const params = { q: sku };
						const product = await getPreOrderProductBySKUAPI(params)
							.then((response) => {
								if (response && response.data) {
									return transformedProduct(response.data.data);
								}

								return null;
							})
							.catch((error) => {
								if (isMulipleSKUsMode) {
									throw error; // Any error in multiple SKUs will handle as multiple errors case
								}

								if (error.response && error.response.status === 422) {
									this.isUnavailable = true;
								} else {
									this.isNotFound = true;
								}
							});

						return product;
					}))
						.then((products) => {
							const validProducts = products.filter((product) => product);
							if (validProducts && validProducts.length > 0) {
								this.$emit('onGetProduct', [
									...validProducts,
									...this.skuList,
								]);

								this.resetState();
							}
						})
						.catch(() => {
							this.isMultipleError = true; // Any error in multiple SKUs mode will be differrent display
						});
				} else {
					this.resetState();
				}
			} catch (error) {
				this.isNotFound = true;
			} finally {
				this.isSubmitting = false;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
	::v-deep .input-group-text {
		padding-right: 0;
	}

	::v-deep .form-get-sku-input {
		margin-right: rem(100);
	}

	.form-sku-input {
		margin-bottom: rem(8);
	}

	.btn-add {
		min-width: rem(84);
	}

	.list-item {
		padding-top: rem(20);
		max-height: rem(320);
		overflow-x: hidden;
		overflow-y: auto;
	}

	.show-border {
		border: solid 1px $color-gray-400;
		border-radius: rem(4);
	}

	.error-multiple {
		background: $color-alert-10;
		border-radius: rem(4);

		.icon-x-circle {
			width: rem(18);
			height: rem(18);
		}
	}
</style>
